import React, { useState, useEffect, useContext } from "react";
import axios from 'axios';
import { useAxios } from "../../utils/axios";
import { ThemeContext } from "../../contexts/ThemeContext";
import { RedisContext } from "../../contexts/RedisContext";
import {FaBell} from "react-icons/fa"
import CustomPopup from '../../components/CustomPopup'; // Import CustomPopup
import { BUILDER_URL } from '../../utils/config';
const BASE_URL = BUILDER_URL;

import { useJobId } from '../../contexts/ContextJbo';
import errorLogApi from '../../components/custom/ErrorLogApi';
/**
 * Subscription component for displaying subscription-related information.
 *
 * This component fetches and displays data for a specific subscription widget.
 * It manages loading states and renders the fetched content.
 *
 * @param {string} widgetId - The ID of the widget for fetching subscription data.
 */
const Subscription = ({widgetId}) => {
  const [data, setData] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const theme = useContext(ThemeContext);
  const {redisWidgetOrderData} = useContext(RedisContext);
  const axiosInstance = useAxios();
  const [email,setEmail]=useState('')
  const { jboId } = useJobId();
  const [isPopupOpen, setIsPopupOpen] = useState(false); // State to handle popup visibility
  const [popupConfig, setPopupConfig] = useState({}); // State to handle popup configuration

  const closePopup = () => {
    setIsPopupOpen(false);
  };
  // Fetching subscription data from the API
 
  useEffect(() => {
    // Find matching widget data based on widgetId
    if (redisWidgetOrderData && widgetId) {
      const widgetData = redisWidgetOrderData.find(
        (widget) => widget.widget_order_id === widgetId
      );
      if (widgetData) {
        setData(widgetData.subscription ? widgetData.subscription[0] : null);
      }
      else{
        setData(null);
      }
    }
  }, [widgetId, redisWidgetOrderData]);

  const handleClick = async (e)=>{
    e.preventDefault;
    if (!email.match(/^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$/)) {
    
      setPopupConfig({
        type: 'error',
        title: 'Error',
        subText: 'Please enter a valid email address.',
        onConfirm: closePopup,
        onClose: closePopup,
        showConfirmButton: false,
        showCancelButton: false,
        autoClose: 3,
      });
      setIsPopupOpen(true);
      return;
    }
    try{
const response = await axiosInstance.post('user/newsletter_subscription',{email:email}).then((res)=>{
  setEmail('');

  setPopupConfig({
    type: 'success',
    title: 'Success',
    subText: res.data.message,
    onConfirm: closePopup,
    onClose: closePopup,
    showConfirmButton: false,
    showCancelButton: false,
    autoClose: 3,
  });
  setIsPopupOpen(true);
})



  }catch(error){
    
    setPopupConfig({
      type: 'error',
      title: 'Error',
      subText: 'Something went wrong',
      onConfirm: closePopup,
      onClose: closePopup,
      showConfirmButton: false,
      showCancelButton: false,
      autoClose: 3,
    });
    setIsPopupOpen(true);
    const jboID = jboId;
    const endpoint = 'user/newsletter_subscription';
    const payLoad = { email: email };
    const responseData = error?.response?.data ? error.response.data : '';
    const errorMsg = error;
    errorLogApi({
      job_id: jboID,
      endpoint: endpoint,
      payload: payLoad,
      responseData: responseData,
      errorMsg: errorMsg
    });
  }
  }
  if (!data) return null;
  // Display a loading indicator while the data is being fetched

  // JSX for rendering the Subscription component
  return (
    
    <div className="mb-2 lg:mb-4 bg-alternative p-4 md:p-8 shadow-md flex flex-col lg:flex-row items-center">
      <div className={`flex items-center space-x-4 flex-shrink-0 ${data.text_side === "right" && "md:order-2 ml-3"}`}>
        <div className="p-2 bg-primary rounded-full">
          <FaBell size={20} className="text-white"/>
        </div>
        <div>
          <h1 className="text-white font-semibold">{data.header_text}</h1>
          <p className="text-white">{data.sub_text}</p>
        </div>
      </div>
    
      <div className="flex-grow  flex md:flex-wrap md:gap-4 flex-col md:flex-row items-center space-y-4 md:space-y-0 md:space-x-4 mt-4 md:mt-4 md:ml-8">
      <input
  type="email"
  value={email}
  onChange={(e) => setEmail(e.target.value)}
  placeholder="Your E-mail Address"
  pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$"
  className="flex-grow p-2 rounded-lg border border-gray-300 focus:border-primary focus:ring-2 focus:ring-primary outline-none"
/>
        
        <button className="py-2 px-6 rounded-lg bg-primary hover:bg-primary transition duration-300 font-semibold text-white flex items-center space-x-2" onClick={handleClick}>
          <span>SUBSCRIBE</span>
          <svg xmlns="https://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M14 5l7 7m0 0l-7 7m7-7H3" />
          </svg>
        </button>
      </div>
      <CustomPopup
        isOpen={isPopupOpen}
        type={popupConfig.type}
        title={popupConfig.title}
        subText={popupConfig.subText}
        onConfirm={popupConfig.onConfirm}
        onClose={popupConfig.onClose}
        showConfirmButton={popupConfig.showConfirmButton}
        showCancelButton={popupConfig.showCancelButton}
        autoClose={popupConfig.autoClose}
      />
    </div>
  )
}

export default Subscription;
