import React, { useState, useEffect, useContext, useRef } from "react";
import axios from "axios";
import { ThemeContext } from "../contexts/ThemeContext";
import { UserContext } from "../contexts/UserContext";
import { useJobId } from "../contexts/ContextJbo";
import Banner from "./HomePageBlocks/Banner";
import Collage from "./HomePageBlocks/Collage";
import { AnimatePresence } from "framer-motion";
import InfoSlider from "./HomePageBlocks/InfoSlider";
import ProductSlider from "./HomePageBlocks/ProductSlider";
import CategorySlider from "./HomePageBlocks/CategorySlider";
import Subscription from "./HomePageBlocks/Subscription";
import CustomSection from "./HomePageBlocks/CustomSection";
import LoadingAnimation from "../components/custom/LoadingAnimation";
import SEO from "../components/custom/SEO";
import { BUILDER_URL } from "../utils/config";
import useTracking from "../components/custom/useTracking";
import { useAxios } from '../utils/axios';
import { RedisContext } from "../contexts/RedisContext";
  // pop up module imported
import HomePagePopup from "../components/custom/HomePagePopup";
const HomePage = () => {
  const [widgets, setWidgets] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const {seoSettingData,redisWidgetOrderData} = useContext(RedisContext);
  const axiosInstance = useAxios();
   // Pop up states
   const [showPopup, setShowPopup] = useState(false);
   const [popupData, setPopupData] = useState(null); // State for dynamic popup data
  const [imageLoadStatus, setImageLoadStatus] = useState({});
  const theme = useContext(ThemeContext);
  const { setDomainData } = useContext(UserContext);
  const [SEOData, setSEOData] = useState({});
  const { jboId } = useJobId();
  const trackPage = useTracking("home");
  const rootRef = useRef(null);

  const fetchWidgets = async () => {
    try {
     
      const sortedWidgets = redisWidgetOrderData.sort(
        (a, b) => a.order_by - b.order_by
      );
      setWidgets(sortedWidgets);
    } catch (error) {
     
      logError("widget-order", error);
    }
  };

  // function to fetch popup
  const fetchPopupData = async () => {
    try {
      const response = await axiosInstance.post(`/settings/getPopupData`);
      if (response.data.status === 1) {
        const popup = response.data.pop_up[0];
        if (popup.status === 1) {
          setPopupData({
            title: popup.popup_title,
            description: popup.popup_desc,
            image: popup.image_url,
            link: popup.popup_link,
            startDate: popup.from_date,
            endDate: popup.to_date,
          });
        }
        // pop up issue fixed
        else{
          setPopupData([]);
        }
      }
    } catch (error) {
      console.error("Error fetching popup data:", error);
    }
  };
  // redis config
  const fetchSeoData = async () => {
    try {
     
      const data = seoSettingData;
      const seoKeywords = data?.seo_keywords?.join(",");
      setDomainData({ seo_domain_name: data.seo_domain_name });
      setSEOData({
        domainName: data.seo_domain_name,
        title: data.seo_title,
        description: data.seo_description,
        keyword: seoKeywords,
      });
      const storedLogoUrl = sessionStorage.getItem("logoUrl");
      const scriptElementSeo = document.createElement("script");
      scriptElementSeo.type = "application/ld+json";
      scriptElementSeo.textContent = JSON.stringify({
        "@context": "https://schema.org",
        "@type": "Organization",
        name: data.seo_domain_name,
        url: window.location.href,
        logo: storedLogoUrl,
      });
      document.head.appendChild(scriptElementSeo);
    } catch (error) {
      console.error("Error fetching SEO data:", error);
      setError(error);  // Set the error in state
    }
  };
// empty commit
// api trigger func
  const loadContent = async () => {
    await Promise.all([fetchWidgets(), fetchSeoData(),fetchPopupData()]);
  };

  useEffect(() => {
    // const logError = (endpoint, error) => {
    //   const responseData = error.response?.data ?? "";
    //   errorLogApi({
    //     job_id: jboId,
    //     endpoint: endpoint,
    //     payload: "",
    //     responseData: responseData,
    //     errorMsg: error,
    //   });
    // };

    loadContent()
      .then((response) => {
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
      });
    trackPage();
  }, [theme.token, jboId]);

  useEffect(() => {
    if (popupData) {
      const popupShown = sessionStorage.getItem("popupShown");
      const today = new Date();
      
      const startDateParts = popupData.startDate.split("-");
      const endDateParts = popupData.endDate.split("-");
      const startDate = new Date(`${startDateParts[2]}-${startDateParts[1]}-${startDateParts[0]}`);
      const endDate = new Date(`${endDateParts[2]}-${endDateParts[1]}-${endDateParts[0]}`);

      today.setHours(0, 0, 0, 0);
      startDate.setHours(0, 0, 0, 0);
      endDate.setHours(0, 0, 0, 0);

      if (!popupShown && today >= startDate && today <= endDate) {
             // Add a 10-second delay before showing the popup
             setTimeout(() => {
              setShowPopup(true);
            }, 10000);
    
      }
    }
  }, [popupData]);
  const handleClosePopup = () => {
    setShowPopup(false);
    sessionStorage.setItem("popupShown", "true");
  }

  const handleImageLoad = (widgetId) => {
    setImageLoadStatus((prevStatus) => ({
      ...prevStatus,
      [widgetId]: true,
    }));
  };

  return (
    <div ref={rootRef}>
      <SEO
        title={SEOData?.title}
        description={SEOData?.description}
        keyword={SEOData?.keyword}
      />
             {showPopup && 
      <HomePagePopup popupData={popupData} onClose={handleClosePopup} />} 
      <div>
{/* Performance and Layout shift updated - 03-09-2024 */}
        <div>
          {widgets.map((widget) => {
            const widgetComponent = (() => {
              switch (widget.widget_type) {

                case "Banner":
                  return (
                    // padding updated - 09/09/2024
                    <div className="sm:min-h-[230px] lg:min-h-[600px] pb-3">
                      <Banner
                        key={widget.widget_order_id}
                        widgetId={widget.widget_order_id}
                        // onImageLoad={() =>
                        //   handleImageLoad(widget.widget_order_id)
                        // }
                      />
                    </div>
                  );
                           // padding updated
                case "Collage":
                  // collage min-height added
                  return (
                    <div className="min-h-[200px] py-3">
                      <Collage
                        key={widget.widget_order_id}
                        widgetId={widget.widget_order_id}
                        onImageLoad={() =>
                          handleImageLoad(widget.widget_order_id)
                        }
                      />
                    </div>
                  );
                           // padding updated
                case "Info Slider":
                  return (
                    <div className="min-h-[220px] py-3">
                      <InfoSlider
                        key={widget.widget_order_id}
                        widgetId={widget.widget_order_id}
                        onImageLoad={() =>
                          handleImageLoad(widget.widget_order_id)
                        }
                      />
                    </div>
                  );
                           // padding updated
                case "Product Slider":
                  return (
                    <div className="min-h-[300px] py-3">
                      <ProductSlider
                        key={widget.widget_order_id}
                        widgetId={widget.widget_order_id}
                        onImageLoad={() =>
                          handleImageLoad(widget.widget_order_id)
                        }
                      />
                    </div>
                  );
                           // padding updated
                case "Category Slider":
                  return (
                    <div className="min-h-[180px] py-3">
                      <div>
                        <CategorySlider
                          key={widget.widget_order_id}
                          widgetId={widget.widget_order_id}
                          onImageLoad={() =>
                            handleImageLoad(widget.widget_order_id)
                          }
                        />
                      </div>
                    </div>
                  );
                           // padding updated
                case "Subscription":
                  return (
                    <Subscription
                      key={widget.widget_order_id}
                      widgetId={widget.widget_order_id}
                      onImageLoad={() =>
                        handleImageLoad(widget.widget_order_id)
                      }
                    />
                  );
                           // padding updated
                case "Cms Custom":
                  return (
                    <div className="min-h-[150px] py-3">
                      <div>
                        <CustomSection
                          key={widget.widget_order_id}
                          widgetId={widget.widget_order_id}
                          onImageLoad={() =>
                            handleImageLoad(widget.widget_order_id)
                          }
                        />
                      </div>
                    </div>
                  );
                default:
                  return null;
              }
            })();

            return widgetComponent;
          })}
        </div>
      </div>
    </div>
  );
};

export default HomePage;
