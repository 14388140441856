import React, { useState, useEffect, useContext } from "react";
import { ThemeContext } from "../../contexts/ThemeContext";
import { RedisContext } from "../../contexts/RedisContext";
import axios from "axios";
import ProductComponent from "../../components/ProductsGrid/RelatedProducts";
// import RelatedAndSimilar from "../../components/ProductsGrid/RelatedAndSimilar";
import LoadingAnimation from '../../components/custom/LoadingAnimation';
import { BUILDER_URL } from '../../utils/config';
import { useJobId } from '../../contexts/ContextJbo';
import errorLogApi from '../../components/custom/ErrorLogApi';
// import { UserContext } from "../../contexts/UserContext";

// Base API Endpoint
const BASE_URL = BUILDER_URL;

const ProductSlider = ({ widgetId }) => {
  const theme = useContext(ThemeContext); // Access Theme Context
  const {redisWidgetOrderData} = useContext(RedisContext);
  const { jboId } = useJobId();
  const [sliderConfig, setSliderConfig] = useState({
    products: null,
    sliderHeader: '',
    numberOfProducts: 0,
    isProductNameVisible: '',
    isPriceVisible: '',
    isSKUVisible: '',
    rotationSeconds: 0
  });
  const [responseData, setResponseData] = useState('');
  // const {setProductSliderConfig} = useContext(UserContext); 

  const handleImageLoaded = (event) => {
    // Directly hide the LoadingAnimation sibling of the img tag
    event.currentTarget.previousSibling.style.display = 'none';
  };
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (!widgetId || !redisWidgetOrderData) return;

    // Find the matching widget based on widgetId from redisWidgetOrderData
    const widgetData = redisWidgetOrderData.find(
      (widget) => widget.widget_order_id === widgetId && widget.widget_type === "Product Slider"
    );

    if (widgetData) {
      // Extract the product slider data
      const productSlider = widgetData.product_slider[0];
      
      setSliderConfig({
        products: productSlider.automatic_product.concat(productSlider.custom_product), // Combine automatic and custom products
        sliderHeader: productSlider.slider_header || widgetData.widget_name,
        numberOfProducts: productSlider.number_of_products,
        isProductNameVisible: productSlider.is_product_name,
        isPriceVisible: productSlider.is_price,
        isSKUVisible: productSlider.is_sku,
        rotationSeconds: productSlider.rotation_seconds,
      });

      setIsLoading(false);
    } else {
      setIsLoading(false);
      console.error(`No widget data found for widgetId: ${widgetId}`);
    }
  }, [widgetId, redisWidgetOrderData]);

  
console.log("sliderConfig products",sliderConfig.products);
  return (
    <div className="block xl:container px-4 pb-8">
      <span className='text-xl lg:text-2xl px-3 lg:px-0 pb-2 lg:pb-8 font-semibold text-primary flex justify-center '>{sliderConfig.sliderHeader}</span>
      {/* <RelatedAndSimilar products={sliderConfig.products} sliderConfigData={sliderConfig}  isLoading={isLoading} isSpecificGrid={false} /> */}
      <ProductComponent products={sliderConfig.products} sliderConfigData={sliderConfig}  isLoading={isLoading} isSpecificGrid={false} />
    </div>
  );
}

export default ProductSlider;
