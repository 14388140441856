import React, { useState, useEffect, useContext, useRef } from 'react';
import SwiperCore, { Navigation, Autoplay } from "swiper/core";
import { Swiper, SwiperSlide } from "swiper/react";
import { ThemeContext } from "../../contexts/ThemeContext";
import { RedisContext } from "../../contexts/RedisContext";
import { BUILDER_URL, setImageSrc } from '../../utils/config';
import axios from "axios";
import LoadingAnimation from '../../components/custom/LoadingAnimation';
import Image from 'next/image'; // Import Next.js Image component

import {
  FaArrowRight,
  FaArrowLeft,
} from "react-icons/fa";

SwiperCore.use([Navigation, Autoplay]);

const BASE_URL = BUILDER_URL;

function getTruncateLength() {
  const screenWidth = window.innerWidth;
  if (screenWidth >= 1280) { // Tailwind's 'xl' breakpoint
    return 56;
  } else if (screenWidth >= 1024) { // Tailwind's 'lg' breakpoint
    return 30;
  } else {
    return 30; // Default for smaller screens
  }
}

const InfoSlider = ({ widgetId, onImageLoad }) => {
  const [data, setData] = useState(null);
  const {redisWidgetOrderData} = useContext(RedisContext);
  const theme = useContext(ThemeContext);
  const [truncateLength, setTruncateLength] = useState(getTruncateLength());
  const [isMobile, setIsMobile] = useState(() => window.innerWidth <= 768);
  const navigationPrevRef = useRef(null);
  const navigationNextRef = useRef(null);

  useEffect(() => {
    const handleResize = () => {
      setTruncateLength(getTruncateLength(window.innerWidth));
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    if (navigationPrevRef.current && navigationNextRef.current) {
      const swiper = document.querySelector('.custom-swiper').swiper;
      swiper.params.navigation.prevEl = navigationPrevRef.current;
      swiper.params.navigation.nextEl = navigationNextRef.current;
      swiper.navigation.init();
      swiper.navigation.update();
    }
   }, [data?.number_of_info]);




  useEffect(() => {
    // Find matching widget data based on widgetId
    if (redisWidgetOrderData && widgetId) {
      const widgetData = redisWidgetOrderData.find(
        (widget) => widget.widget_order_id === widgetId
      );
      if (widgetData) {
        setData(widgetData.infoSlider
          ? widgetData.infoSlider
          [0] : null);
      }
      else{
        setData(null);
      }
    }
  }, [widgetId, redisWidgetOrderData]);

  if(!data) return null;

  const truncateText = (text) => {
    return text?.length <= truncateLength ? text : `${text?.substring(0, truncateLength)}...`;
  };

  return (
    <>
      {data && (
        <div className="block xl:container px-4 mb-2 mx-auto mt-1">
          <span className='text-xl lg:text-2xl px-3 lg:px-0 font-semibold text-primary flex justify-center mb-2 lg:mb-4'>
            {data.widget_title}
          </span>
          {data.is_slider_enabled || isMobile ? (
            <>
            <div className="relative ">
              <Swiper
                key={data.number_of_info} // Ensure Swiper updates when the number of info changes
                className="custom-swiper"
                spaceBetween={10}
                centeredSlides={false} // Set centeredSlides to false to avoid empty space
      
                breakpoints={{
                  320: { slidesPerView: 1, spaceBetween: 10 },
                  480: { slidesPerView: 2, spaceBetween: 10 },
                  640: { slidesPerView: 3, spaceBetween: 10 },
                  768: { slidesPerView: 3, spaceBetween: 10 },
                  1024: { slidesPerView: data.number_of_info, spaceBetween: 40 },
                  1280: { slidesPerView: data.number_of_info, spaceBetween: 40 },
                }}
                autoplay={{ delay: 4500, disableOnInteraction: false }}
                // navigation={true}
                navigation={{
                  prevEl: navigationPrevRef.current, // Use the ref here
                  nextEl: navigationNextRef.current, // And here
                }}
                onBeforeInit={(swiper) => {
                  swiper.params.navigation.prevEl = navigationPrevRef.current;
                  swiper.params.navigation.nextEl = navigationNextRef.current;
                }}
                onSwiper={swiper => {
                  setTimeout(() => {
                    swiper.params.navigation.prevEl = navigationPrevRef.current
                    swiper.params.navigation.nextEl = navigationNextRef.current
                    swiper.navigation.destroy()
                    swiper.navigation.init()
                    swiper.navigation.update()
                  })
                }}
              >
                {data?.info_slider_box.map((info, index) => (
                  <SwiperSlide key={index}>
                    <div
                      className="flex flex-col items-center justify-between rounded-md p-3 mx-auto h-44 md:h-52 lg:h-52"
                      style={{ backgroundColor: info.background_colour }}
                    >
                      <div className="flex items-center justify-center w-20 h-20 lg:w-12 lg:h-12 lg1:w-20 lg1:h-20 bg-white rounded-full p-1">
                        {/* <LoadingAnimation /> */}
                        <Image
                          src={setImageSrc(info.icon, '')}
                          alt=""
                          className="w-full h-full object-cover rounded-full"
                          layout="fixed"
                          width={80} // Set width for the image
                          height={80} // Set height for the image
                        />
                      </div>
                      <h3
                        className="font-semibold text-lg text-center w-full px-2 lg:text-sm lg1:text-lg"
                        style={{ color: info.header_colour }}
                        title={info.header_text}
                      >
                        {info.header_text}
                      </h3>
                      <div className="px-2 w-full">
                        <p className="text-sm text-center" style={{ color: info.text_colour }} title={info.sub_text}>
                          {info.sub_text}
                        </p>
                      </div>
                    </div>
                  </SwiperSlide>
                ))}
              </Swiper>
              {data?.info_slider_box?.length > data?.number_of_info &&
              <>
                <div ref={navigationNextRef}  className="swiper-button-next z-10 bg-gray-200 p-2 rounded-full absolute top-1/2 right-0 transform -translate-y-1/2 cursor-pointer">
                  <FaArrowRight color="black" size={20} />
                </div>
                <div ref={navigationPrevRef}  className="swiper-button-prev z-10 bg-gray-200 p-2 rounded-full absolute top-1/2 left-0 transform -translate-y-1/2 cursor-pointer">
                  <FaArrowLeft color="black" size={20} />
                </div>
              </>
              }
            </div>
            </>
          ) : (
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-3 gap-4">
              {data.info_slider_box.map((info, index) => (
              <div key={index} className="p-4">
                <div
                  className="flex flex-col items-center justify-between rounded-md p-3 mx-auto h-44 lg:h-52"
                  style={{ backgroundColor: info.background_colour }}
                >
                  <div className="flex items-center justify-center w-20 h-20 bg-white rounded-full p-1">
                    {/* <LoadingAnimation /> */}
                    <Image
                      src={setImageSrc(info.icon, '')}
                      alt=""
                      className="w-full h-full object-cover rounded-full"
                      layout="fixed"
                      width={80} // Set width for the image
                      height={80} // Set height for the image
                    />
                  </div>
                  <h3
                    className="font-semibold text-sm text-center truncate w-full px-2"
                    style={{ color: info.header_colour }}
                    title={info.header_text}
                  >
                    {info.header_text}
                  </h3>
                  <div className="overflow-auto px-2 w-full" style={{ maxHeight: '3rem' }}>
                    <p className="text-sm text-center" style={{ color: info.text_colour }} title={info.sub_text}>
                      {info.sub_text}
                    </p>
                  </div>
                </div>
              </div>
              ))}
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default InfoSlider;
