import React, { useEffect, useState,useRef, useContext } from "react";
import { UserContext } from "../../contexts/UserContext";
import { motion } from "framer-motion";
import { setImageSrc } from "../../utils/config"; // Utility to set image source
import { AiOutlineClose } from "react-icons/ai"; // Import the close icon
import CustomPopup from "../../components/CustomPopup"; 
const HomePagePopup = ({ popupData, onClose }) => {
    const popupRef = useRef(null); // Reference for the popup
    const { userDetails } = useContext(UserContext);
    const [isPopupOpen, setPopupOpen] = useState(false);
    const [popupConfig, setPopupConfig] = useState({});

     // Custom Pop up function 
  const openPopup = (config) => {
    setPopupConfig(config);
    setPopupOpen(true);
  };

  const closePopup = () => {
    setPopupOpen(false);
  };
    // Handle outside click to close popup
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (popupRef.current && !popupRef.current.contains(event.target)) {
                onClose(); // Close the popup when clicking outside
            }
        };
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [onClose]);

    // Handle image click with conditional redirection
    const handleImageClick = () => {
        if (popupData.link) {
            console.log("popupData Link",popupData.link);
            // Check if the link includes '/auth/SignIn' or '/auth/Register'
            if (
                popupData.link.includes("/auth/SignIn") ||
                popupData.link.includes("/auth/Register")
            ) {
                console.log("true");
                // Check if the user is already signed in
                if (userDetails && userDetails.userId !== null) {
                    // Show message to inform the user they are already signed in
                    openPopup({
                        type: 'warning',
                        title: 'Action Not Required',
                        subText: `You are already signed in, no need to Sign In or Register again.`,
                        onConfirm: closePopup,
                        onClose: closePopup,
                        autoClose: 2,
                        showConfirmButton: false,
                        showCancelButton: false,
                      });
                } else {
                    // Redirect to the link if the user is not signed in
                    window.open(popupData.link, "_blank");
                }
            } else {
                // If the link does not contain sign-in or register, proceed with redirection
                window.open(popupData.link, "_blank");
            }
        }
    };

    return (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
            <motion.div
                ref={popupRef} // Attach the popup reference
                initial={{ opacity: 0, scale: 0.8 }}
                animate={{ opacity: 1, scale: 1 }}
                transition={{ duration: 0.5, ease: "easeInOut" }}
                className="bg-white rounded-lg p-6 w-full max-w-2xl shadow-lg max-h-[90vh] overflow-auto"
            >
                {/* Header with title and close button */}
                <div className="relative mb-4">
                    <h2 className="text-2xl font-bold">{popupData.title}</h2>
                    <button
                        className="absolute top-2 right-0 text-black hover:text-red-500"
                        onClick={onClose}
                    >
                        <AiOutlineClose size={23} /> {/* icon added */}
                    </button>
                </div>

                {/* Image section */}
                <div className="mb-4">
                    <div
                        className="relative w-full h-80 cursor-pointer"
                        onClick={handleImageClick}
                    >
                        <div className="relative w-full h-full">
                            {/* Image rendering */}
                            <img
                                src={setImageSrc(popupData.image)} // Dynamically set the image source
                                alt="Popup"
                                className="w-full h-full object-contain rounded-lg" // Ensure image fits within the container
                            />
                        </div>
                    </div>
                </div>

                {/* Description section */}
                <div className="text-gray-700">
                    <p>{popupData.description}</p>
                </div>
            </motion.div>
            <CustomPopup
        isOpen={isPopupOpen}
        type={popupConfig.type}
        title={popupConfig.title}
        subText={popupConfig.subText}
        onConfirm={closePopup}
        onClose={closePopup}
        autoClose={popupConfig.autoClose}
        confirmLabel="Ok"
        cancelLabel="Close"
        showConfirmButton={popupConfig.showConfirmButton}
        showCancelButton={popupConfig.showCancelButton}
        showCloseIcon={true}
        redirectOnClose={popupConfig.redirectOnClose} // Pass redirect path
      />
        </div>
    );
};

export default HomePagePopup;
